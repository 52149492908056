import { selectDefaultModeFor } from "@/modes";
import { changeMode } from "@/store/mode-slice";
import { setActiveElement } from "@/store/selections-slice";
import { RootState } from "@/store/store";
import { IElementBase } from "@faro-lotv/ielement-types";
import { Dispatch } from "@reduxjs/toolkit";

/**
 * Method for marking the given element active, using the default mode for that element type
 *
 * @param element element to mark as active
 * @param store current store state
 * @param dispatch store dispatch function
 */
export function selectNode(
  element: IElementBase,
  store: RootState,
  dispatch: Dispatch,
): void {
  const elementMode = selectDefaultModeFor(element)(store);

  if (elementMode) {
    dispatch(changeMode(elementMode.targetMode));
    dispatch(setActiveElement(elementMode.element.id));
  } else {
    dispatch(setActiveElement(element.id));
  }
}
