import { Dropdown, Option } from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import {
  IElementSection,
  IElementTypeHint,
  WithHint,
} from "@faro-lotv/ielement-types";
import { useMemo } from "react";

type AreaSelectorProps = {
  sectionAreas: Array<WithHint<IElementSection, IElementTypeHint.area>>;
  activeAreaId?: GUID;
  onAreaSelect(areaId?: GUID): void;
};

/** Fake ID to use for selecting all areas */
const ALL_AREAS_ID = "all";

/**
 * @returns A dropdown for selecting the active area
 */
export function AreaSelector({
  sectionAreas,
  activeAreaId = ALL_AREAS_ID,
  onAreaSelect,
}: AreaSelectorProps): JSX.Element {
  const options: Option[] = useMemo(
    () => [
      {
        key: ALL_AREAS_ID,
        value: ALL_AREAS_ID,
        label: "All areas",
      },
      ...sectionAreas.map((sectionArea) => ({
        key: sectionArea.id,
        value: sectionArea.id,
        label: sectionArea.name,
      })),
    ],
    [sectionAreas],
  );

  return (
    <Dropdown
      options={options}
      value={activeAreaId}
      onChange={({ target: { value } }) => {
        onAreaSelect(value === ALL_AREAS_ID ? undefined : value);
      }}
    />
  );
}
