import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { FaroText } from "@faro-lotv/flat-ui";
import { Stack } from "@mui/system";
import { selectPointClouds } from "./area-navigation-selectors";
import { AreaContentProps } from "./area-navigation-types";
import { selectNode } from "./area-navigation-utils";
import { ElementNameWithIcon } from "./element-with-icon";

/**
 * @returns A list of all point clouds in the active area (all if there is no active area)
 */
export function PointCloudCaptures({
  activeAreaId,
}: AreaContentProps): JSX.Element | null {
  const pointClouds = useAppSelector((state) =>
    selectPointClouds(state, activeAreaId),
  );

  const store = useAppStore();
  const dispatch = useAppDispatch();

  if (!pointClouds.length) return null;

  return (
    <Stack sx={{ py: 1 }}>
      <FaroText variant="heading14" sx={{ py: 1.5 }}>
        3D captures
      </FaroText>
      <Stack>
        {pointClouds.map((pointCloud) => {
          return (
            <ElementNameWithIcon
              elementName={Element.name}
              element={pointCloud}
              key={pointCloud.id}
              onClick={() => selectNode(pointCloud, store.getState(), dispatch)}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}
