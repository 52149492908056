import { EventType } from "@/analytics/analytics-events";
import { MarkupAnnotationUI } from "@/components/ui/annotations/markup-annotation-ui";
import { useDeleteAnnotation } from "@/hooks/use-delete-annotation";
import { useAppSelector } from "@/store/store-hooks";
import {
  convertIElementToThreePositionParsed,
  selectIElementWorldMatrix4,
} from "@/utils/transform-conversion-parsed";
import {
  ANNOTATION_ZINDEX_RANGE_MAP,
  AnnotationIcon,
  AnnotationViewerVariants,
  MarkupPolygonIcon,
  MeasurementMarkerIcon,
  neutral,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import {
  IElementGenericAnnotation,
  IElementType,
  isIElementMarkup,
  isIElementMarkupPolygon,
  isIElementMeasurePolygon,
} from "@faro-lotv/ielement-types";
import { selectChildDepthFirst } from "@faro-lotv/project-source";
import { animated, useSpringValue } from "@react-spring/web";
import { isEqual } from "lodash";
import { CSSProperties, useCallback, useMemo } from "react";
import { Material, Matrix4, Object3D, Vector3 } from "three";
import { useCopyToClipboard } from "../annotation-hooks";
import {
  useAnnotationSorterFadeChanged,
  useAnnotationSorterForceVisible,
  useAnnotationSorterWorldMatrix,
  useAnnotationVisibility,
} from "../annotation-sorter";
import { AnnotationVisibility } from "../annotation-utils";
import { AnnotationWrapper } from "../annotation-wrapper";
import { AlignAt } from "../annotations-types";
import { useHandleAnnotationViewerVariant } from "./use-handle-annotation-details-variant";

/** Size, in pixels, of the icon used to render polygon markups */
const MARKUP_ICON_SIZE = 30;

type MarkupAnnotationRendererProps = {
  /** The annotation to render */
  annotation: IElementGenericAnnotation;

  /** The world offset applied to the pano associated to these markups */
  worldTransform: Matrix4;

  /** Whether depth testing should be used to render the annotation */
  depthTest?: Material["depthTest"];

  /** The render order to use for the annotation */
  renderOrder?: Object3D["renderOrder"];
};

/** @returns handles the rendering of annotation of type markup */
export function MarkupAnnotationRenderer({
  annotation,
  worldTransform,
  depthTest,
  renderOrder,
}: MarkupAnnotationRendererProps): JSX.Element | null {
  const markup = useAppSelector(
    selectChildDepthFirst(annotation, isIElementMarkup),
  );

  const annotationWorldTransform = useAppSelector(
    selectIElementWorldMatrix4(annotation.id),
    isEqual,
  );

  const anchorPoint = useAnnotationAnchorPoint(annotation);

  const transform = useMemo(
    () =>
      new Matrix4().multiplyMatrices(worldTransform, annotationWorldTransform),
    [annotationWorldTransform, worldTransform],
  );

  const anchorPointWorld = useMemo(() => {
    return anchorPoint.clone().applyMatrix4(transform);
  }, [anchorPoint, transform]);

  const visibility = useAnnotationVisibility(annotation.id);

  const {
    AnnotationViewerVariant,
    onAnnotationViewerHovered,
    onAnnotationViewerExpanded,
    onAnnotationViewerClosed,
  } = useHandleAnnotationViewerVariant(
    markup?.id ?? annotation.id,
    visibility === AnnotationVisibility.Minified,
    visibility === AnnotationVisibility.NotVisible,
  );

  const { uiStyle, alignAt } = useAnnotationStyle(
    annotation,
    AnnotationViewerVariant,
  );

  useAnnotationSorterWorldMatrix(annotation.id, transform);
  useAnnotationSorterForceVisible(
    annotation.id,
    AnnotationViewerVariant === "full",
  );

  // Using a spring bypasses reactivity for faster, high-frequency updates
  const fadeSpring = useSpringValue(0);
  const onAnnotationFadeChanged = useCallback(
    (fade: number) => {
      fadeSpring.set(fade);
    },
    [fadeSpring],
  );

  useAnnotationSorterFadeChanged(annotation.id, onAnnotationFadeChanged);

  // Optional icon to render next to the UI. Used for single point annotations
  const icon = useMemo(() => {
    if (
      AnnotationViewerVariant === "collapsed" ||
      AnnotationViewerVariant === "hidden"
    ) {
      return (
        <animated.div style={{ opacity: fadeSpring }}>
          <CollapsedAnnotationIcon annotation={annotation} />
        </animated.div>
      );
    }

    if (isIElementMarkupPolygon(annotation)) {
      return <MarkupPolygonIcon sx={{ fontSize: `${MARKUP_ICON_SIZE}px` }} />;
    }
  }, [annotation, AnnotationViewerVariant, fadeSpring]);

  const copyToClipboard = useCopyToClipboard(annotation);

  const { removeAnnotation, isRemovingAnnotation } = useDeleteAnnotation(
    annotation.id,
  );

  if (!markup || isRemovingAnnotation || AnnotationViewerVariant === "hidden") {
    return null;
  }

  return (
    <AnnotationWrapper
      iElement={annotation}
      worldTransform={worldTransform}
      onHoverChange={onAnnotationViewerHovered}
      popOverAlwaysVisible
      alignAt={alignAt}
      anchorPointWorld={anchorPointWorld}
      eps={-1}
      zIndexRange={ANNOTATION_ZINDEX_RANGE_MAP[AnnotationViewerVariant]}
      isCollapsed={AnnotationViewerVariant === "collapsed"}
      depthTest={depthTest}
      renderOrder={renderOrder}
      style={uiStyle}
    >
      <MarkupAnnotationUI
        markup={markup}
        AnnotationViewerVariant={AnnotationViewerVariant}
        onAnnotationViewerExpanded={onAnnotationViewerExpanded}
        onAnnotationViewerClosed={onAnnotationViewerClosed}
        icon={icon}
        iconSize={MARKUP_ICON_SIZE}
        onCopyToClipboard={copyToClipboard}
        onDelete={() => {
          Analytics.track(EventType.deleteAnnotation);
          removeAnnotation();
        }}
      />
    </AnnotationWrapper>
  );
}

type CollapsedAnnotationIconProps = {
  /** The IElement of the annotation. It's used to choose which icon to render. */
  annotation: IElementGenericAnnotation;
};

/** @returns The icon to render for a collapsed annotation */
function CollapsedAnnotationIcon({
  annotation,
}: CollapsedAnnotationIconProps): JSX.Element {
  if (isIElementMeasurePolygon(annotation)) {
    return <MeasurementMarkerIcon sx={{ fontSize: `${MARKUP_ICON_SIZE}px` }} />;
  }

  return (
    <AnnotationIcon
      sx={{
        background: neutral[900],
        borderRadius: "50%",
        outline: `2px solid ${neutral[0]}`,
        padding: 0.5,
        color: neutral[0],
        width: `${MARKUP_ICON_SIZE - 2}px`,
        height: `${MARKUP_ICON_SIZE - 2}px`,
      }}
    />
  );
}

type UseAnnotationStyleReturn = {
  /** Additional styles needed to customize the label */
  uiStyle: CSSProperties;

  /** Position of the label relative to the object, in case the anchor point is not given */
  alignAt: AlignAt;
};

/**
 * The anchor point is an additional local offset of an annotation IElement, at which the "main position" should be considered.
 * For example to determine where a collapsed annotation should be shown, or where markup UI should be anchored to.
 *
 * @param annotation IElement of the annotation to render
 * @returns the anchor point of the annotation
 */
function useAnnotationAnchorPoint(
  annotation: IElementGenericAnnotation,
): Vector3 {
  return useMemo(() => {
    switch (annotation.type) {
      case IElementType.markupPolygon: {
        // The MarkupPolygon will have the same position for the anchor point as the 3d object
        return convertIElementToThreePositionParsed(annotation.points[0]);
      }
      case IElementType.measurePolygon: {
        const points = annotation.points.map(
          convertIElementToThreePositionParsed,
        );
        // Place the annotation at the "left-most" point
        return points.reduce(
          (prev, next) => {
            if (next.x < prev.x) prev.copy(next);
            return prev;
          },
          new Vector3(
            Number.POSITIVE_INFINITY,
            Number.POSITIVE_INFINITY,
            Number.POSITIVE_INFINITY,
          ),
        );
      }
      default:
        return new Vector3();
    }
  }, [annotation]);
}

/**
 * @param annotation IElement of the annotation to render
 * @param variant current variant of the annotation
 * @returns the style to apply to the annotation's ui and how the annotation should be aligned
 */
function useAnnotationStyle(
  annotation: IElementGenericAnnotation,
  variant: AnnotationViewerVariants,
): UseAnnotationStyleReturn {
  let uiStyle: CSSProperties = {};
  let alignAt: AlignAt = AlignAt.center;

  switch (annotation.type) {
    case IElementType.markupPolygon: {
      alignAt = AlignAt.anchorPoint;

      // Move the UI to the top so that the icon bottom-left corner points
      // to the markup polygon position
      uiStyle = {
        transform: `translate(0px, ${-MARKUP_ICON_SIZE}px)`,
      };
      break;
    }
    case IElementType.measurePolygon: {
      alignAt = AlignAt.anchorPoint;
      break;
    }
    case IElementType.model3d: {
      alignAt =
        variant === "full"
          ? AlignAt.boundingBoxTopRight
          : AlignAt.boundingBoxTopLeft;

      uiStyle = {
        transform:
          variant === "full"
            ? "translate(20px, 0px)"
            : "translate(20px, -10px)",
      };
      break;
    }
  }

  return { uiStyle, alignAt };
}
