import { useProjectSubTree } from "@/components/common/project-provider/project-loading-context";
import { setActiveElement } from "@/store/selections-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { FaroText } from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/ielement-types";
import { selectProjectName, selectRootId } from "@faro-lotv/project-source";
import { Box, Stack } from "@mui/system";
import { useCallback, useState } from "react";
import { selectAreaSections } from "./area-navigation-selectors";
import { AreaSelector } from "./area-selector";
import { Models } from "./models";
import { PanoCaptures } from "./pano-captures";
import { PointCloudCaptures } from "./point-cloud-captures";

/**
 * @returns TODO: Add docs
 */
export function AreaNavigation(): JSX.Element {
  const [activeAreaId, setActiveAreaId] = useState<GUID>();
  const sectionAreas = useAppSelector(selectAreaSections);

  const dispatch = useAppDispatch();

  const updateActiveArea = useCallback(
    (areaId?: GUID) => {
      setActiveAreaId(areaId);

      if (areaId) dispatch(setActiveElement(areaId));
    },
    [dispatch],
  );

  const projectName = useAppSelector(selectProjectName);
  const rootId = useAppSelector(selectRootId);
  const isLoading = useProjectSubTree(rootId);

  if (isLoading) {
    return <Box component="div">LOADING</Box>;
  }

  return (
    <Stack flexGrow={1}>
      <FaroText
        variant="heading16"
        sx={{
          paddingBottom: 3,
        }}
      >
        {projectName}
      </FaroText>

      <AreaSelector
        sectionAreas={sectionAreas}
        activeAreaId={activeAreaId}
        onAreaSelect={updateActiveArea}
      />

      <Stack>
        <Models />
        <PointCloudCaptures
          sectionAreas={sectionAreas}
          activeAreaId={activeAreaId}
        />
        <PanoCaptures sectionAreas={sectionAreas} activeAreaId={activeAreaId} />
      </Stack>
    </Stack>
  );
}
