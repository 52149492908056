import { FaroText } from "@faro-lotv/flat-ui";
import { IElementBase } from "@faro-lotv/ielement-types";
import { Stack } from "@mui/system";
import { ElementIcon, useElementIcon } from "../icons";

type TextWithIconProps = {
  elementName: string;
  element: Pick<IElementBase, "id" | "type" | "typeHint">;
  onClick?(): void;
};

/**
 * @returns The name of the given element with the appropriate icon in front of it
 */
export function ElementNameWithIcon({
  element,
  onClick,
  elementName,
}: TextWithIconProps): JSX.Element {
  const icon = useElementIcon(element);
  return (
    <Stack direction="row" onClick={onClick}>
      <ElementIcon icon={icon} sx={{ fontSize: "1.125em" }} />
      <FaroText variant="bodyM">{elementName}</FaroText>
    </Stack>
  );
}
