import { useUpdateProjectMetadata } from "@/components/common/project-provider/use-project-metadata";
import { THRESHOLD_SET_STATIONARY } from "@/registration-tools/common/registration-report/registration-thresholds";
import { ThresholdSetProvider } from "@/registration-tools/common/registration-report/threshold-set-context";
import { useAppSelector } from "@/store/store-hooks";
import { generateViewerUrl } from "@/utils/redirects";
import { FaroStepper } from "@faro-lotv/flat-ui";
import {
  getProjectPreparedDataPage,
  selectDashboardUrl,
} from "@faro-lotv/project-source";
import {
  RegistrationRevision,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { Stack } from "@mui/material";
import { useMemo, useState } from "react";
import { SphereViewerToolHeaderBar } from "../components/ui/tool-header-bar";
import {
  DataPreparationStepKey,
  useDataPreparationSteps,
  useLatestDataPreparationStep,
} from "./data-preparation-stepper";
import { useLoadRegistrationReport } from "./loading/use-load-registration-report";
import { useLoadRevisionPointCloudStreams } from "./loading/use-load-revision-point-cloud-streams";
import { RevisionLoadingSpinner } from "./registration/registration-revision-loading-spinner";
import { RevisionScansScene } from "./rendering/revision-scans-scene";
import {
  selectPointCloudStreamIdsForHoveredEntity,
  selectPointCloudStreamIdsForSelectedEntity,
} from "./store/data-preparation-ui/data-preparation-ui-selectors";
import { selectRevisionScans } from "./store/revision-selectors";
import { DataPreparationBanners } from "./ui/data-preparation-banners";
import { DataPreparationReportSidebar } from "./ui/data-preparation-report-sidebar";
import { DataPreparationSidebar } from "./ui/data-preparation-sidebar";

export type DataPreparationUiProps = {
  /** The revision that is used for the data preparation. */
  revision: RegistrationRevision;
};

/**
 * @returns UI for the data preparation page.
 */
export function DataPreparationUi({
  revision,
}: DataPreparationUiProps): JSX.Element {
  const { projectApiClient } = useApiClientContext();

  // Non-blocking: Load project meta-data, e.g. to get the project name for the breadcrumbs
  useUpdateProjectMetadata(projectApiClient.projectId);

  const defaultStepKey = useLatestDataPreparationStep(revision.state);
  const steps = useDataPreparationSteps(revision.state);
  const [activeStepKey, setActiveStepKey] = useState(defaultStepKey);

  const dashboardUrl = useAppSelector(selectDashboardUrl);

  const scanEntities = useAppSelector(selectRevisionScans);
  const pointCloudStreams = useLoadRevisionPointCloudStreams(scanEntities);

  const hoveredPointCloudIds = useAppSelector(
    selectPointCloudStreamIdsForHoveredEntity,
  );
  const selectedPointCloudIds = useAppSelector(
    selectPointCloudStreamIdsForSelectedEntity,
  );

  const registrationReport = useLoadRegistrationReport(revision.reportUri);
  const [isReportOpen, setIsReportOpen] = useState(false);

  const sphereProjectUrl = useMemo(
    () => generateViewerUrl(projectApiClient.projectId),
    [projectApiClient.projectId],
  );

  const exitHref = dashboardUrl
    ? getProjectPreparedDataPage(dashboardUrl, projectApiClient.projectId)
    : sphereProjectUrl;

  return (
    <Stack
      sx={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <SphereViewerToolHeaderBar
        toolName="Data Preparation"
        exitHref={exitHref}
      />
      <FaroStepper
        activeStepKey={activeStepKey}
        steps={steps}
        onStepChange={(step) => setActiveStepKey(step.key)}
      />
      <ThresholdSetProvider defaultThresholdSet={THRESHOLD_SET_STATIONARY}>
        <Stack sx={{ height: "100%", width: "100%" }}>
          <DataPreparationBanners
            activeStepKey={activeStepKey}
            revision={revision}
            scanEntities={scanEntities}
            pointCloudStreams={pointCloudStreams}
          />

          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <DataPreparationSidebar
              activeStepKey={activeStepKey}
              revision={revision}
              registrationReport={registrationReport}
              isReportOpen={isReportOpen}
              onToggleReport={setIsReportOpen}
              projectId={projectApiClient.projectId}
            />

            <RevisionScansScene
              scanEntities={scanEntities}
              pointCloudStreams={pointCloudStreams}
              hoveredPointCloudIds={hoveredPointCloudIds}
              selectedPointCloudIds={selectedPointCloudIds}
              registrationReport={
                // The registration results should only be visualized in the inspect and publish step
                activeStepKey === DataPreparationStepKey.inspectAndPublish
                  ? registrationReport
                  : undefined
              }
              overlay={
                activeStepKey === DataPreparationStepKey.registration ? (
                  <RevisionLoadingSpinner revisionState={revision.state} />
                ) : undefined
              }
            />

            <DataPreparationReportSidebar
              registrationReport={registrationReport}
              isReportOpen={
                activeStepKey === DataPreparationStepKey.inspectAndPublish &&
                isReportOpen
              }
              onToggleReport={setIsReportOpen}
            />
          </Stack>
        </Stack>
      </ThresholdSetProvider>
    </Stack>
  );
}
