import { FaroButton, FaroTooltip, neutral } from "@faro-lotv/flat-ui";
import { MouseEvent, useCallback } from "react";

type ProjectTreeActionButtonProps = {
  /** Name of the button */
  name: string;

  /** optional tooltip for the button */
  tooltip?: string;

  /** Function called when the button is clicked */
  onClick?(): void;

  /** true if the button should be disabled (visible but user cannot click on it) */
  disabled?: boolean;
};

/**
 * @returns a button that will be used for actions in the project tree
 */
export function ProjectTreeActionButton({
  name,
  tooltip,
  onClick,
  disabled,
}: ProjectTreeActionButtonProps): JSX.Element {
  const handleClick = useCallback(
    (ev: MouseEvent) => {
      if (onClick) {
        ev.stopPropagation();
        onClick();
      }
    },
    [onClick],
  );

  return (
    <FaroTooltip title={tooltip}>
      <FaroButton
        aria-label={name}
        variant="ghost"
        size="xs"
        sx={{
          textTransform: "none",
          color: "inherit",
          fontWeight: 600,
          fontSize: "0.625rem",
          lineHeight: 1,
          px: 0.5,
          py: 0.25,
          minWidth: 0,
          "&:hover": {
            // 10% opacity is 19 in hexadecimal
            backgroundColor: `${neutral[500]}19`,
          },
        }}
        onClick={handleClick}
        disabled={disabled}
      >
        {name}
      </FaroButton>
    </FaroTooltip>
  );
}
