import { RootState } from "@/store/store";
import { GUID } from "@faro-lotv/foundation";
import { isIElementAreaSection } from "@faro-lotv/ielement-types";
import {
  selectAreaDataSessions,
  selectChildrenDepthFirst,
  selectIElement,
  selectRootIElement,
} from "@faro-lotv/project-source";
import { createSelector } from "@reduxjs/toolkit";

export const selectPointClouds = createSelector(
  [
    (state: RootState) => state,
    (state: RootState, refElementId?: GUID) =>
      selectAreaSections(state, refElementId),
  ],
  (state, areaSections) => {
    return areaSections
      .map((areaSection) => selectAreaDataSessions(areaSection)(state))
      .flat();
  },
);

export const selectAreaSections = createSelector(
  [
    (state: RootState) => state,
    (state, refElementId?: GUID) =>
      refElementId
        ? selectIElement(refElementId)(state)
        : selectRootIElement(state),
  ],
  (state, refElement) => {
    return selectChildrenDepthFirst(refElement, isIElementAreaSection)(state);
  },
);
