import {
  CompassGoToIcon,
  FaroChipList,
  FaroChipStatus,
  FaroChipTag,
  FaroIconButton,
  FaroMenuSeparator,
  FaroTooltip,
  NO_TRANSLATE_CLASS,
  TruncatedFaroText,
} from "@faro-lotv/flat-ui";
import { Grid } from "@mui/material";
import { Stack } from "@mui/system";
import { CardAttachmentIcon } from "./card-attachment-icon";
import { CardContextMenu } from "./card-context-menu";
import { CardUserAvatar } from "./card-user-avatar";
import { AnnotationCardProps } from "./responsive-card-types";
import {
  RESPONSIVE_CARDS_HEIGHT,
  ResponsiveCardVariant,
} from "./responsive-card-variant";

/** @returns A card with a layout on three different lines */
export function SmallCard({
  name,
  attachments,
  tags,
  state,
  mainAssignee,
  markup,
  dueDate,
  onGoTo,
}: AnnotationCardProps): JSX.Element {
  return (
    <Stack height={RESPONSIVE_CARDS_HEIGHT[ResponsiveCardVariant.small]}>
      <Stack sx={{ p: 1.5 }} gap={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <TruncatedFaroText
            variant="heading12"
            className={NO_TRANSLATE_CLASS}
            containerSx={{ flexGrow: 1 }}
          >
            {name}
          </TruncatedFaroText>
          <FaroTooltip title="Go to annotation">
            <FaroIconButton
              size="s"
              aria-label="go to annotation"
              onClick={onGoTo}
            >
              <CompassGoToIcon />
            </FaroIconButton>
          </FaroTooltip>
          <CardContextMenu
            markup={markup}
            mainAssignee={mainAssignee}
            state={state}
            dueDate={dueDate}
          />
        </Stack>
        <Grid container rowSpacing={0.5} columnSpacing={1} alignItems="center">
          <Grid item xs="auto">
            <div style={{ width: "100px" }}>
              <FaroChipStatus status={state} />
            </div>
          </Grid>
          <Grid item xs sx={{ minWidth: 0 }}>
            {tags && (
              <FaroChipList
                size="small"
                sx={{ flexGrow: 1 }}
                chips={tags.map((t) => (
                  <FaroChipTag
                    className={NO_TRANSLATE_CLASS}
                    key={t.id}
                    label={t.label}
                    color={t.color}
                  />
                ))}
              />
            )}
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="space-between">
          <CardAttachmentIcon count={attachments.length} />
          <CardUserAvatar userDisplayInfo={mainAssignee} />
        </Stack>
      </Stack>
      <FaroMenuSeparator />
    </Stack>
  );
}
