import { FaroStep } from "@faro-lotv/flat-ui";
import { RegistrationState } from "@faro-lotv/service-wires";
import { useMemo } from "react";

/** The keys identifying each step of the data preparation workflow. */
export enum DataPreparationStepKey {
  registration = "registration",
  inspectAndPublish = "inspect-and-publish",
}

/**
 * @param revisionState The current state of the revision.
 * @returns The key of the latest valid step in the data preparation workflow.
 */
export function useLatestDataPreparationStep(
  revisionState: RegistrationState,
): DataPreparationStepKey {
  return useMemo(() => {
    switch (revisionState) {
      case RegistrationState.started:
      case RegistrationState.cloudRegistrationStarted:
        return DataPreparationStepKey.registration;
      case RegistrationState.registered:
      case RegistrationState.merged:
      case RegistrationState.canceled:
        return DataPreparationStepKey.inspectAndPublish;
    }
  }, [revisionState]);
}

/**
 * @param revisionState The current state of the revision.
 * @returns The steps in the data preparation workflow.
 */
export function useDataPreparationSteps(
  revisionState: RegistrationState,
): Array<FaroStep<DataPreparationStepKey>> {
  const latestStep = useLatestDataPreparationStep(revisionState);

  return useMemo(() => {
    return [
      {
        key: DataPreparationStepKey.registration,
        label: "Registration",
        allowNext: () =>
          latestStep === DataPreparationStepKey.inspectAndPublish,
      },
      {
        key: DataPreparationStepKey.inspectAndPublish,
        label: "Inspect and publish",
        allowBack: () => true,
      },
    ] satisfies Array<FaroStep<DataPreparationStepKey>>;
  }, [latestStep]);
}
