import { selectAllCadModels } from "@/store/cad/cad-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { FaroText } from "@faro-lotv/flat-ui";
import { Stack } from "@mui/system";
import { ElementNameWithIcon } from "./element-with-icon";

/**
 * @returns A list of all the 3D models of the project
 */
export function Models(): JSX.Element | null {
  const cadModels = useAppSelector(selectAllCadModels);

  if (!cadModels.length) return null;

  return (
    <Stack sx={{ py: 1 }}>
      <FaroText variant="heading14" sx={{ py: 1.5 }}>
        3D model
      </FaroText>

      <Stack>
        {cadModels.map((model) => {
          return (
            <ElementNameWithIcon
              element={model}
              elementName={model.name}
              key={model.id}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}
