import { useTrackElementSizes } from "@/hooks/use-track-element-sizes";
import { neutral } from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/ielement-types";
import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import { CSSProperties, useMemo, useState } from "react";
import { FixedSizeList as List, ListChildComponentProps } from "react-window";
import { AnnotationListCard } from "./annotation-list-card";
import {
  cardVariantFromWidth,
  RESPONSIVE_CARDS_HEIGHT,
  ResponsiveCardVariant,
} from "./annotation-list-card/responsive-card-variant";

export type AnnotationListProps = {
  /** All annotations to show in the list */
  markups?: GUID[];
  /** Whether the annotations are still being fetched*/
  markupsLoading?: boolean;
};

type AnnotationItemData = {
  /** The list of markups */
  markups: GUID[];

  /** The card variant to use */
  cardVariant: ResponsiveCardVariant;
};

/** @returns a list of annotations cards */
export function AnnotationList({
  markups,
  markupsLoading = false,
}: AnnotationListProps): JSX.Element {
  const [container, setContainer] = useState<HTMLElement>();
  const { width, height } = useTrackElementSizes(container);

  const data = useMemo(
    () => ({
      markups: markups ?? [],
      cardVariant: cardVariantFromWidth(width),
    }),
    [markups, width],
  );

  const cardHeight = RESPONSIVE_CARDS_HEIGHT[data.cardVariant];

  return (
    <Box
      component="div"
      ref={setContainer}
      sx={{ flexGrow: 1, width: "100%", m: 0, p: 0, overflow: "hidden" }}
    >
      <List<AnnotationItemData>
        itemData={data}
        height={height}
        overscanCount={10}
        // Add one extra item while loading to render a skeleton at the end of the list
        itemCount={data.markups.length + (markupsLoading ? 1 : 0)}
        width={width}
        itemSize={cardHeight}
        itemKey={(index, data) => data.markups[index]}
      >
        {AnnotationItem}
      </List>
    </Box>
  );
}

/** Properties for a single annotation list item  */
type AnnotationItemProps = ListChildComponentProps & {
  /** Index of the item in the list */
  index: number;

  /** List data */
  data: AnnotationItemData;

  /** Extra style to apply to the list item container managed by react-window */
  style?: CSSProperties;
};

/** @returns A single annotation item in the list, managed by react-window*/
function AnnotationItem({
  index,
  data,
  style,
}: AnnotationItemProps): JSX.Element | null {
  const markup = data.markups.at(index);

  // If the last markup is not available the list is still loading
  if (!markup) {
    return (
      <Skeleton
        sx={{ width: "100%", height: "32px", bgcolor: neutral[800], ...style }}
      />
    );
  }

  return (
    <AnnotationListCard
      key={markup}
      markupId={markup}
      sx={style}
      variant={data.cardVariant}
    />
  );
}
